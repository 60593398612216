import React from 'react'

const BackgroundShapes: React.FC = () => {
    return (
        <svg
            className='absolute bottom-[19rem] lg:bottom-20'
            viewBox='0 0 1994 1079'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g opacity='1' filter='url(#footerBlur)'>
                <path
                    d='M1558.64 363c63.14 0 96.7 71.162 50.19 113.859C1447.74 624.736 1232.91 715.002 997 715.002c-235.91 0-450.738-90.266-611.83-238.143C338.658 434.162 372.221 363 435.359 363H1558.64Z'
                    fill='#67B6FF'
                />
            </g>
            <defs>
                <filter
                    id='footerBlur'
                    x='.46'
                    y='0'
                    width='1993.08'
                    height='1078'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                    <feGaussianBlur
                        stdDeviation='181.5'
                        result='effect1_foregroundBlur_1429_16394'
                    />
                </filter>
            </defs>
        </svg>
    )
}
export default BackgroundShapes
