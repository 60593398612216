import React from 'react'
import * as CC from '@chakra-ui/react'
import { PageSectionConfig } from '../../types'
import Image from 'next/image'
import classNames from 'classnames'
import * as HeroIcons from '@heroicons/react/solid'

const Feature: React.FC<PageSectionConfig> = ({
    id,
    title,
    subtitle,
    body,
    media,
    alignment = 'left',
    items = null,
}) => {
    return (
        <section
            className={classNames(
                'relative mx-auto mb-12 flex w-full max-w-screen-xl flex-col items-center justify-center gap-6 overflow-hidden px-8 md:mb-16 md:gap-8 md:px-8 lg:mb-36 lg:px-16',
                alignment === 'left' ? 'md:flex-row' : 'md:flex-row-reverse'
            )}
            id={id}
        >
            <div className='md:max-w-1/2 relative flex w-full items-center justify-center'>
                <figure className='relative w-full px-8 md:max-w-lg md:px-12'>
                    <Image
                        src={media?.path}
                        width={media?.width}
                        height={media?.height}
                        layout='responsive'
                        alt={media?.alt}
                        priority={true}
                        className='max-h-48 w-auto md:max-h-64 lg:max-h-80'
                    />
                </figure>
            </div>
            <CC.VStack alignItems='left' className='max-w-prose' gap={{ base: 4, md: 8 }}>
                {subtitle && (
                    <CC.Badge
                        rounded='full'
                        px={3}
                        py={1}
                        size='lg'
                        bg='green.100'
                        color='green.500'
                        textTransform='capitalize'
                        w='fit-content'
                        fontSize='sm'
                    >
                        {subtitle}
                    </CC.Badge>
                )}
                {title && (
                    <CC.Heading size='2xl' as='h1' fontWeight='normal'>
                        {title}
                    </CC.Heading>
                )}

                {body || ''}
                {items && (
                    <CC.List spacing={4}>
                        {items.map((item, i) => (
                            <CC.ListItem display='flex' key={item?.id}>
                                <CC.ListIcon
                                    mt={1}
                                    as={HeroIcons.CheckCircleIcon}
                                    color='green.500'
                                />
                                {item.title}
                            </CC.ListItem>
                        ))}
                    </CC.List>
                )}
            </CC.VStack>
        </section>
    )
}

export default Feature
