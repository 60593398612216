import React from 'react'
import * as CC from '@chakra-ui/react'
import { PageSectionConfig } from '../../types'
import Image from 'next/image'
import classNames from 'classnames'

const Grid: React.FC<PageSectionConfig> = ({ id, title, subtitle, body, items }) => {
    return (
        <section
            className='flex justify-center overflow-hidden bg-blue-50 py-12 md:py-16 lg:py-36'
            id={id}
        >
            <div className='w-full max-w-screen-xl gap-12 px-8 lg:px-16'>
                <CC.VStack alignItems='center' gap={16}>
                    <CC.VStack gap={4}>
                        {title && (
                            <CC.Heading
                                textAlign='center'
                                size='2xl'
                                as='h1'
                                className='text-blue-700'
                                fontWeight='normal'
                            >
                                {title}
                            </CC.Heading>
                        )}
                        {subtitle && (
                            <CC.Text className='text-xl text-blue-600' textAlign='center'>
                                {subtitle}
                            </CC.Text>
                        )}
                    </CC.VStack>

                    {body || ''}
                    {items && (
                        <CC.SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 4, md: 10 }}>
                            {items.map((item, i) => (
                                <CC.Box key={item?.id} p={8} bg='white' rounded='xl'>
                                    <CC.Heading className='text-xl' mb={4}>
                                        {item.title}
                                    </CC.Heading>
                                    <CC.Text className='text-gray-400'>{item.subtitle}</CC.Text>
                                </CC.Box>
                            ))}
                        </CC.SimpleGrid>
                    )}
                </CC.VStack>
            </div>
        </section>
    )
}

export default Grid
