import React from 'react'
import * as CC from '@chakra-ui/react'
import { PageSectionConfig } from '../../types'
import Image from 'next/image'
import BackgroundShapes from './BackgroundShapes'

const Hero: React.FC<PageSectionConfig> = ({ id, title, subtitle, body, media }) => {
    return (
        <section
            className='relative flex flex-col items-center justify-center gap-8 overflow-hidden bg-gray-50 px-8 pt-12 md:flex-row md:pt-24'
            id={id}
        >
            <div className='relative order-2 mt-0 flex w-full max-w-xs flex-col items-center justify-center md:order-1 md:min-w-72 md:max-w-lg'>
                <figure className='relative w-full max-w-sm px-8 md:max-w-lg xl:px-12'>
                    <div className='scale-115 absolute bottom-0 left-0 flex w-full items-center justify-center blur-xl md:-bottom-8 lg:bottom-0'>
                        <BackgroundShapes />
                    </div>
                    <Image
                        src={media?.path}
                        width={media?.width}
                        height={media?.height}
                        layout='responsive'
                        alt={media?.alt}
                        priority={true}
                    />
                </figure>
            </div>
            <CC.VStack
                className='max-w-prose items-start justify-center text-center md:items-center md:text-left'
                gap={[6, 6, 6, 10]}
            >
                {title && (
                    <CC.Heading size={['xl', 'xl', 'xl', '2xl']} as='h1'>
                        {title}
                    </CC.Heading>
                )}
                {subtitle && (
                    <CC.Heading size='lg' as='h2'>
                        {subtitle}
                    </CC.Heading>
                )}
                {body || ''}
            </CC.VStack>
        </section>
    )
}

export default Hero
