import React from 'react'
const BackgroundShapes: React.FC = () => {
    return (
        <svg
            width='574'
            height='495'
            viewBox='0 0 574 495'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M90.0402 140.635C68.6095 80.3416 107.018 0 171.006 0C216.706 0 251.341 40.8727 246.639 86.3303C231.599 231.739 247.181 305.665 250.613 441.136C251.385 471.624 233.074 499.448 203.922 508.409C174.58 517.429 137.611 525.932 111.375 522.025C103.886 520.909 96.1563 519.448 88.423 517.768C41.6807 507.614 23.0517 453.397 44.2324 410.51C72.7834 352.699 104.625 275.19 104.625 217.043C104.625 191.376 98.8212 165.339 90.0402 140.635Z'
                fill='#E6F8BF'
            />
            <path
                d='M483.96 382.365C505.391 442.658 466.982 523 402.994 523C357.294 523 322.659 482.127 327.361 436.67C342.401 291.261 326.819 217.335 323.387 81.864C322.615 51.3762 340.926 23.5518 370.078 14.5909C399.42 5.57109 436.389 -2.9322 462.625 0.975332C470.114 2.09069 477.844 3.5523 485.577 5.23223C532.319 15.3862 550.948 69.603 529.768 112.49C501.217 170.301 469.375 247.81 469.375 305.957C469.375 331.624 475.179 357.661 483.96 382.365Z'
                fill='#B8DDFF'
            />
        </svg>
    )
}

export default BackgroundShapes
