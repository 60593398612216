import React from 'react'
import * as CC from '@chakra-ui/react'
import { PageSectionConfig } from '../../types'
import * as HeroIcons from '@heroicons/react/solid'
import Image from 'next/image'
import classNames from 'classnames'

const FAQs: React.FC<PageSectionConfig> = ({ id, title, subtitle, body, items }) => {
    return (
        <section
            className=' relative flex flex-col items-center justify-center gap-6 overflow-hidden px-8 py-16 md:px-16 md:py-16 lg:py-36'
            id={id}
        >
            <CC.VStack
                alignItems='center'
                gap={12}
                w='full'
                className='max-w-screen-xl px-8 lg:px-16'
            >
                <CC.VStack gap={4}>
                    {title && (
                        <CC.Heading fontWeight='extrabold' textAlign='center' size='2xl' as='h1'>
                            {title}
                        </CC.Heading>
                    )}
                    {subtitle && <CC.Text textAlign='center'>{subtitle}</CC.Text>}
                </CC.VStack>

                {body || ''}
                {items && (
                    <CC.Accordion allowToggle w='full'>
                        {items.map((item, i) => (
                            <CC.AccordionItem key={item?.id} border='none' mb={4}>
                                {({ isExpanded }) => (
                                    <>
                                        <CC.AccordionButton
                                            className='flex w-full justify-between rounded-lg bg-gray-50 p-4 text-left hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-50'
                                            _expanded={{
                                                bg: 'gray.100',
                                            }}
                                        >
                                            <CC.Box flex='1' textAlign='left'>
                                                <CC.Heading
                                                    size='md'
                                                    as='h2'
                                                    fontWeight='normal'
                                                    className='text-lg text-gray-400'
                                                >
                                                    {item.title}
                                                </CC.Heading>
                                            </CC.Box>

                                            {isExpanded ? (
                                                <CC.Icon
                                                    as={HeroIcons.MinusSmIcon}
                                                    className='h-6 w-6 text-gray-400'
                                                />
                                            ) : (
                                                <CC.Icon
                                                    as={HeroIcons.PlusSmIcon}
                                                    className='h-6 w-6 text-gray-400'
                                                />
                                            )}
                                        </CC.AccordionButton>
                                        <CC.AccordionPanel pb={4}>
                                            <CC.Text>{item?.subtitle}</CC.Text>
                                        </CC.AccordionPanel>
                                    </>
                                )}
                            </CC.AccordionItem>
                        ))}
                    </CC.Accordion>
                )}
            </CC.VStack>
        </section>
    )
}

export default FAQs
