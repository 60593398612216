import React from 'react'
import * as CC from '@chakra-ui/react'
import { PageSectionConfig } from '../../types'
import * as HeroIcons from '@heroicons/react/solid'
import Image from 'next/image'
import classNames from 'classnames'
import BackgroundShapes from './BackgroundShapes'

const CTA: React.FC<PageSectionConfig> = ({ id, title, subtitle, body, items }) => {
    return (
        <section
            className='relative flex flex-col items-center justify-center gap-12 overflow-hidden p-4 px-8 py-12 md:px-16 md:py-16 md:pb-48 lg:py-36'
            id={id}
        >
            <BackgroundShapes />
            <div className='z-10 max-w-screen-xl'>
                <CC.VStack zIndex={1} alignItems='center' gap={16} w='full'>
                    <CC.VStack gap={4}>
                        {title && (
                            <CC.Heading
                                fontWeight='extrabold'
                                textAlign='center'
                                size='2xl'
                                as='h1'
                                w={{ base: 'full', lg: '80%' }}
                            >
                                {title}
                            </CC.Heading>
                        )}
                        {subtitle && (
                            <CC.Text
                                className='text-lg font-semibold text-gray-400'
                                textAlign='center'
                                w={{ base: 'full', lg: '70%' }}
                            >
                                {subtitle}
                            </CC.Text>
                        )}
                    </CC.VStack>

                    {body || ''}
                </CC.VStack>
            </div>
        </section>
    )
}

export default CTA
