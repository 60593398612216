import React from 'react'
import * as CC from '@chakra-ui/react'
import { PageSectionConfig } from '../../types'

const Stats: React.FC<PageSectionConfig> = ({ id, items }) => {
    return (
        <section
            className='flex justify-center overflow-hidden bg-green-500 py-12 md:py-16 lg:py-36'
            id={id}
        >
            <div className='flex w-full max-w-screen-xl flex-col items-center justify-between gap-12 px-8 lg:flex-row lg:px-16'>
                {items &&
                    items?.map((item) => (
                        <CC.VStack key={item?.id} alignItems='center' gap={{ base: 4, md: 6 }}>
                            <p
                                className='text-4xl font-extrabold text-white md:text-6xl'
                                key={item.id}
                            >
                                {item?.title}
                            </p>
                            <p className='text-center text-lg font-normal text-white' key={item.id}>
                                {item?.subtitle}
                            </p>
                        </CC.VStack>
                    ))}
            </div>
        </section>
    )
}

export default Stats
