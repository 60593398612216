import React from 'react'
import * as CC from '@chakra-ui/react'
import * as UI from '@brewlogix/blx-ui'
import { PageSectionItem } from '../../types'
import classNames from 'classnames'

interface ISectionItem {
    item: PageSectionItem
}
const SectionItem: React.FC<ISectionItem> = ({ item }) => {
    const iconFillColor = item?.icon ? item.iconTheme.split(' ')[1] : ''
    return (
        <div className='flex max-w-prose flex-col items-center gap-4 rounded-lg md:items-start'>
            {item?.icon && (
                <div
                    className={classNames(
                        'flex aspect-square w-16 flex-col items-center justify-center rounded-md p-2',
                        item.iconTheme
                    )}
                >
                    <UI.Icon slug={item.icon} size='lg' customClasses={iconFillColor} />
                </div>
            )}
            <CC.Heading size='md'>{item?.title}</CC.Heading>
            <CC.Text
                fontSize={['md', 'md', 'md', 'lg']}
                color='gray.400'
                textAlign={['center', 'center', 'left']}
            >
                {item?.subtitle}
            </CC.Text>
        </div>
    )
}

export default SectionItem
