import React from 'react'
import * as CC from '@chakra-ui/react'
import { PageSectionConfig } from '../../types'
import Image from 'next/image'
import BackgroundShapes from '../Hero/BackgroundShapes'

const MediaCTA: React.FC<PageSectionConfig> = ({ id, title, subtitle, body, media }) => {
    return (
        <section className='my-16 flex w-full justify-center overflow-hidden rounded-xl px-4 md:my-32'>
            <div
                className='container relative flex flex-col items-center justify-center gap-8 overflow-hidden rounded-xl bg-gray-50 px-8 pt-12 md:flex-row-reverse md:pt-24'
                id={id}
            >
                <div className='relative order-2 w-full max-w-xs md:order-1 md:min-w-72 md:max-w-lg'>
                    <figure className='relative w-full max-w-sm px-8 md:max-w-lg xl:px-12'>
                        <div className='absolute left-0 flex w-full scale-110 items-center justify-center blur-xl md:-bottom-8 lg:bottom-0'>
                            <BackgroundShapes />
                        </div>
                        <Image
                            src={media?.path}
                            width={media?.width}
                            height={media?.height}
                            layout='responsive'
                            alt={media?.alt}
                            priority={true}
                        />
                    </figure>
                </div>
                <CC.VStack
                    className='flex max-w-prose items-center justify-center text-center md:items-start md:text-left'
                    gap={[6, 6, 6, 10]}
                >
                    {title && (
                        <CC.Heading size={['xl', 'xl', 'xl', '2xl']} as='h1'>
                            {title}
                        </CC.Heading>
                    )}
                    {subtitle && (
                        <CC.Heading size='lg' as='h2'>
                            {subtitle}
                        </CC.Heading>
                    )}
                    {body || ''}
                </CC.VStack>
            </div>
        </section>
    )
}

export default MediaCTA
