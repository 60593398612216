import React from 'react'
import * as CC from '@chakra-ui/react'
import { PageSectionConfig } from '../../types'
import SectionItem from '../partials/SectionItem'

const Columns: React.FC<PageSectionConfig> = ({ id, title, subtitle, items }) => {
    return (
        <section
            className='container mx-auto my-12 w-full max-w-screen-xl overflow-hidden px-8 md:my-16 lg:my-36 lg:px-16'
            id={id}
        >
            <header className='flex items-center justify-center gap-6'>
                {title && (
                    <CC.Heading size='2xl' as='h1'>
                        {title}
                    </CC.Heading>
                )}
                {subtitle && (
                    <CC.Heading size='lg' as='h2'>
                        {subtitle}
                    </CC.Heading>
                )}
            </header>
            <CC.Stack
                alignContent='center'
                justifyContent='center'
                gap={[10, 10, 12]}
                flexDirection={['column', 'column', 'row']}
                w='full'
            >
                {items?.map((item, index) => (
                    <SectionItem key={index} item={item} />
                ))}
            </CC.Stack>
        </section>
    )
}

export default Columns
