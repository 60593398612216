enum PageSections {
    Columns = 'Columns',
    Hero = 'Hero',
    Feature = 'Feature',
    Grid = 'Grid',
    Stats = 'Stats',
    FAQs = 'FAQs',
    CTA = 'CTA',
    MediaCTA = 'MediaCTA',
}

export default PageSections
